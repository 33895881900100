const $ = require('jquery');
export function anchor (){
  $(function(){
    $('.innerLink[href^="#"]').click(function () {
  		smooth(this);
  		return false;
  	});

  	$('#pageTopBtn button').click(function () {
  		animateoffset(0);
  		return false;
  	});

  	//smoothScroll
  	function smooth(target){
  		var href = $(target).attr('href');//引数targetが持つhref値を取得
  		//var offset = $('#pageHeader').height();
  		var offset = 80;//何px下に下げるかの数値
  		var top = $(href).offset().top - offset;
  		animateoffset(top);//実際にスクロールさせる関数animateoffsetを実行
  	}
  	function animateoffset(top){
  		$('html,body').animate({scrollTop:top},1000);//引数topの値までスクロールさせる
  	}
  });
}
