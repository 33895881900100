const $ = require('jquery');
export function current() {
  const path = location.pathname;
  if(path.match('contact'))
  {

  }
  else if(path.match('about'))
  {
    $('.j-current_about').addClass('current');
  }
  else if(path.match('service'))
  {
    $('.j-current_service').addClass('current');
  }
  else if(path.match('news'))
  {
    $('.j-current_news').addClass('current');
  }
  else if(path.match(''))
  {
    $('.j-current_home').addClass('current');
  }
}
